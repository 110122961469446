<template>
  <div>
    <div id="container" ref="container"></div>
  </div>
</template>

<script>
export default {
  props: ['addme'],
  data() {
    return {
      tMap: null,
      tMarker: null,
      tMarkerLabel: null,
      position: {},
      center: '',
      city: sessionStorage.getItem('city') || '福州'
    }
  },
  created() {},
  mounted() {
    // console.log(this.addme);
    // this.mapRender()
    // this.mapRender()
    this.mapRender()

    this.bus.$on('getmap', async val => {
      // val += ''

      if (val == 110100) {
        val = 110000
      } else if (val == 120100) {
        val = 120000
      } else if (val == 310100) {
        val = 310000
      }

      // val=val+
      // val = val.slice(0, val.length - 4)  + '0000'
      // console.log(val)
      this.city = val
      // this.tMap = null
      const url = `https://apis.map.qq.com/ws/district/v1/search?&keyword=${this.city}&key=OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK`
      // this.tMap = null
      const res = await this.$jsonp(url, {
        output: 'jsonp'
      })

      // console.log(res.result[0][0].location)
      if (res.result) {
        this.center = res.result[0][0].location
        sessionStorage.setItem('center', JSON.stringify(this.center))
        this.tMap.panTo(
          new window.TMap.LatLng(this.center.lat, this.center.lng)
        )
        this.$forceUpdate()
      }

      // this.mapRender()
      // console.log();
    })
    this.bus.$on('pushaddress', val => {
      // console.log(val);
      var ent = new window.TMap.LatLng(val.lat, val.lng)
      this.drawMarker(ent)
      this.tMap.panTo(new window.TMap.LatLng(val.lat, val.lng))
    })
  },
  methods: {
    async mapRender() {
      // 初始化地图
      if (
        sessionStorage.getItem('center') &&
        !!JSON.parse(sessionStorage.getItem('center')).lat
      ) {
      } else {
        const url = `https://apis.map.qq.com/ws/district/v1/search?&keyword=${this.city}&key=OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK`
        // this.tMap = null
        const res = await this.$jsonp(url, {
          output: 'jsonp'
        })
        console.log(res.result)
        // console.log(res.result[0][0].location)
        this.center = res.result[0][0].location

        // console.log(this.center)
        sessionStorage.setItem('center', JSON.stringify(this.center))
      }

      this.center = JSON.parse(sessionStorage.getItem('center'))
      console.log(this.center)
      var center = new window.TMap.LatLng(this.center.lat, this.center.lng)
      this.tMap = new window.TMap.Map('container', {
        rotation: 20, // 设置地图旋转角度
        pitch: 0, // 设置俯仰角度（0~45）
        zoom: 12, // 设置地图缩放级别
        center: center,
        draggableCursor:
          'https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/c1.cur', //设置鼠标拖拽元素样式
        draggingCursor:
          'https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/c2.cur' //设置鼠标移动样式
      })
      console.log(center)
      this.drawMarker(center)
      // this.drawMarkerLabel(evt)
      // this.tMap.centerAndZoom('福州', 12);
      this.tMap.on('click', evt => {
        console.log(evt)
        // console.log('evt==', evt)
        const lat = evt.latLng.getLat().toFixed(6)
        const lng = evt.latLng.getLng().toFixed(6)
        this.position = { lat, lng }
        this.drawMarker(evt.latLng)
        this.drawMarkerLabel(evt)
        this.$emit('position', this.position)
      })
    },
    async mapcenter() {
      const url = `https://apis.map.qq.com/ws/district/v1/search?&keyword=${this.city}&key=OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK`
      // this.tMap = null
      const res = await this.$jsonp(url, {
        output: 'jsonp'
      })

      // console.log(res.result[0][0].location)
      this.center = res.result[0][0].location
      this.tMap.panTo(new window.TMap.LatLng(this.center.lat, this.center.lng))
    },
    drawMarker(evt) {
      // 绘制marker点
      if (this.tMarker) {
        this.tMarker.setMap(null)
        this.tMarker = null
      }
      this.tMarker = new window.TMap.MultiMarker({
        id: 'marker-layer',
        map: this.tMap
      })
      this.tMarker.add({
        position: evt
      })
    },
    drawMarkerLabel(evt) {
      if (this.tMarkerLabel) {
        this.tMarkerLabel.setMap(null)
        this.tMarkerLabel = null
      }
      const { lat, lng } = evt.latLng
      const center = new TMap.LatLng(lat, lng)
      this.tMarkerLabel = new window.TMap.MultiLabel({
        id: 'markerLabelayer',
        map: this.tMap,
        styles: {
          label: new TMap.LabelStyle({
            color: '#333', //颜色属性
            size: 16, //文字大小属性
            offset: { x: 0, y: 0 }, //文字偏移属性单位为像素
            angle: 0, //文字旋转属性
            alignment: 'center', //文字水平对齐属性
            verticalAlignment: 'middle', //文字垂直对齐属性
            background: ' #fff'
          })
        },
        geometries: [
          {
            id: 'label', //点图形数据的标志信息
            styleId: 'label', //样式id
            position: center, //标注点位置
            content:
              '坐标位置' +
              '(' +
              this.position.lat +
              ',' +
              this.position.lng +
              ')', //标注文本
            properties: {
              //标注点的属性数据
              title: 'label'
            }
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
#container {
  width: 100%;
  height: 100%;
}
#info {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 14px;
  background: #fff;
  width: 270px;
  padding: 10px;
  border-radius: 3px;
}
</style>
